'use client';

import { useEffect } from 'react';

declare global {
  interface Window {
    VoiceAI: (action: string, config?: { assistantId: string }) => void;
  }
}

export const AiqidoWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.aiqido.co/js/voice-ai-widget.min.js';
    script.defer = true;
    script.async = true;

    script.onload = () => {
      window.VoiceAI('init', { assistantId: 'cm2jd0w8v000110ym3ydpw1sr' });
    };

    document.body.appendChild(script);

    return () => {
      if (window.VoiceAI) {
        window.VoiceAI('destroy');
      }
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return null;
};
