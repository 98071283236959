'use client';

import { useState, useEffect } from 'react';
import Link from 'next/link';

// Define the menu items with navigation types
type MenuItem = {
  label: string;
  href: string;
  isExternal?: boolean;
};

// Updated menu items with correct demo link
const menuItems: MenuItem[] = [
  { label: 'Solution', href: 'solution' },
  { label: 'Avantages', href: 'benefits' },
  /*{ label: 'Témoignages', href: 'testimonials' },*/
  { label: 'FAQ', href: 'faq' },
  { label: 'Demo', href: '/demo/artisan', isExternal: true },
];

// Helper function for smooth scrolling to section
const smoothScroll = (target: string) => {
  const element = document.querySelector(`#${target}`);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

export const Navbar = () => {
  const [mounted, setMounted] = useState(false);

  // Use useEffect to avoid hydration mismatch
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;

  return (
    <nav className="hidden md:flex gap-x-4">
      {menuItems.map((item) =>
        item.isExternal ? (
          <Link
            key={item.href}
            href={item.href}
            className="cursor-pointer hover:text-primary font-semibold"
          >
            {item.label}
          </Link>
        ) : (
          <a
            key={item.href}
            className="cursor-pointer hover:text-primary font-semibold"
            onClick={() => {
              smoothScroll(item.href);
            }}
          >
            {item.label}
          </a>
        )
      )}
    </nav>
  );
};
