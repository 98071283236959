'use client';

import { Montserrat } from 'next/font/google';
import { cn } from '@/lib/utils';
import { ThemeToggle } from '@/components/theme-toggle';
import { Button } from '@/components/ui/button';
import { Navbar } from './navbar';
import Link from 'next/link';
import { useModal } from '@/hooks/use-modal-store';
import { useSession } from 'next-auth/react';

const font = Montserrat({
  weight: '600',
  subsets: ['latin'],
});

export const Header = () => {
  const { onOpen } = useModal();
  const { data: session } = useSession();

  return (
    <header className="fixed top-0 left-0 right-0 z-50 transition-all duration-300 bg-white dark:bg-gray-900 py-4 shadow-md">
      <div className="container mx-auto px-4 flex items-center justify-between">
        <div className="flex items-center gap-x-8">
          <Link href="/">
            <button className="flex items-center">
              <h1 className={cn('text-2xl font-bold text-gray-900 dark:text-white', font.className)}>AIQIDO</h1>
            </button>
          </Link>
          <Navbar />
        </div>
        <div className="flex items-center gap-x-4">
          <Button
            className="bg-blue-600 hover:bg-blue-700 text-white transition-colors"
            onClick={() => onOpen('calendly')}
          >
            Demander une démo
          </Button>
          {session && (
            <Link href="/dashboard">
              <Button>Dashboard</Button>
            </Link>
          )}
          <ThemeToggle />
        </div>
      </div>
    </header>
  );
};
