'use client';

import { Button } from '@/components/ui/button';
import { ArrowRight, Calendar } from 'lucide-react';
import { useModal } from '@/hooks/use-modal-store';

export const FinalCTASection = () => {
  const { onOpen } = useModal();

  return (
    <section className="py-20 bg-gradient-to-br from-blue-600 to-indigo-700 text-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-4xl md:text-5xl font-bold mb-6">Prêt à révolutionner votre engagement client ?</h2>
          <p className="text-xl mb-8">
            Rejoignez les entreprises leaders qui utilisent AIQIDO pour transformer leurs visiteurs en clients fidèles.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <Button
              size="lg"
              className="w-full sm:w-auto text-lg px-8 py-6 bg-white text-blue-600 hover:bg-blue-50 transition-all duration-300"
              onClick={() => onOpen('calendly')}
            >
              Demander une démo
              <Calendar className="ml-2 h-5 w-5" />
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
