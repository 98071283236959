'use client';

import { Button } from '@/components/ui/button';
import { Sparkles, ArrowRight, ImageIcon, Calendar } from 'lucide-react';
import { useCallback } from 'react';
import { useModal } from '@/hooks/use-modal-store';

export const HeroSection = () => {
  const { onOpen } = useModal();

  const scrollTo = useCallback((elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      history.pushState(null, '', `#${elementId}`);
    }
  }, []);

  return (
    <div
      id="hero"
      className="pt-[120px] bg-white dark:bg-gray-900 text-gray-900 dark:text-white min-h-screen flex items-center"
    >
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl md:text-6xl font-extrabold mb-6 leading-tight">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600">
              L'IA vocale qui détecte
              <br />
              et qualifie vos intentionnistes
              <br />
              sur votre site.
            </span>
          </h1>
          <p className="text-xl md:text-2xl font-light mb-12 text-gray-700 dark:text-gray-300">
            Utilisez Aiqido pour identifier les visiteurs à fort potentiel et engager automatiquement des conversations
            adaptées à leurs besoins.
          </p>
          <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
            <Button
              size="lg"
              className="w-full md:w-auto text-lg px-8 py-6 rounded-full font-semibold bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 transition-all duration-300 transform hover:scale-105"
              onClick={() => onOpen('calendly')}
            >
              Demandez une démo gratuite
              <Calendar className="ml-2 h-5 w-5" />
            </Button>
            <Button
              variant="outline"
              size="lg"
              className="w-full md:w-auto text-lg px-8 py-6 rounded-full font-semibold border-2 border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white dark:border-blue-400 dark:text-blue-400 dark:hover:bg-blue-400 dark:hover:text-gray-900 transition-all duration-300"
              onClick={() => scrollTo('benefits')}
            >
              Découvrez les avantages
              <Sparkles className="ml-2 h-5 w-5" />
            </Button>
          </div>
        </div>
        {/*
        <div className="mt-16 text-center">
          <p className="text-sm font-medium text-gray-600 dark:text-gray-400">Ils nous font confiance :</p>
          <div className="mt-4 flex justify-center space-x-8">
            <div className="w-24 h-12 bg-gray-200 dark:bg-gray-700 shadow-sm rounded-md flex items-center justify-center">
              <ImageIcon className="h-8 w-8 text-gray-400 dark:text-gray-500" />
            </div>
            <div className="w-24 h-12 bg-gray-200 dark:bg-gray-700 shadow-sm rounded-md flex items-center justify-center">
              <ImageIcon className="h-8 w-8 text-gray-400 dark:text-gray-500" />
            </div>
            <div className="w-24 h-12 bg-gray-200 dark:bg-gray-700 shadow-sm rounded-md flex items-center justify-center">
              <ImageIcon className="h-8 w-8 text-gray-400 dark:text-gray-500" />
            </div>
          </div>
        </div>
        */}
      </div>
    </div>
  );
};
