'use client';

import { useState } from 'react';
import { ChevronDown } from 'lucide-react';

const faqList = [
  {
    question: "Comment fonctionne la solution d'IA vocale d'AIQIDO ?",
    answer:
      "Notre IA analyse en temps réel le comportement des visiteurs sur votre site web pour identifier ceux qui ont une forte intention d'achat. Elle engage ensuite automatiquement une conversation vocale adaptée à leurs besoins, répondant à leurs questions et les guidant dans le processus d'achat.",
  },
  {
    question: "Est-ce qu'AIQIDO s'intègre avec mon CRM existant ?",
    answer:
      "Oui, AIQIDO s'intègre facilement avec la plupart des CRM populaires tels que Salesforce, HubSpot, et Microsoft Dynamics. Notre interface d'intégration permet une connexion rapide et sécurisée, assurant une synchronisation fluide des données des leads qualifiés.",
  },
  {
    question: 'Combien de temps faut-il pour mettre en place AIQIDO ?',
    answer:
      "La mise en place d'AIQIDO est extrêmement rapide. En tant que solution SaaS, vous pouvez être opérationnel en moins de 2 minutes. Il vous suffit de créer un compte, d'ajouter un simple script à votre site web, et notre IA est prête à engager vos visiteurs.",
  },
  {
    question: "AIQIDO peut-il s'adapter à mon secteur d'activité spécifique ?",
    answer:
      "Absolument. AIQIDO est conçu pour être hautement personnalisable et s'adapter à divers secteurs d'activité. Notre interface intuitive vous permet d'ajuster rapidement les paramètres de l'IA pour qu'elle comprenne les spécificités de votre industrie et communique de manière pertinente avec vos prospects.",
  },
  {
    question: 'Comment AIQIDO améliore-t-il concrètement les taux de conversion ?',
    answer:
      "AIQIDO améliore les taux de conversion de plusieurs façons : en engageant proactivement les visiteurs au bon moment, en répondant instantanément à leurs questions, en qualifiant les leads en temps réel, et en guidant les prospects à travers le tunnel de vente. Nos clients constatent généralement une augmentation des taux de conversion de 20 à 35% dès les premières semaines d'utilisation.",
  },
  {
    question: 'Quelles mesures de sécurité et de confidentialité AIQIDO met-il en place ?',
    answer:
      'La sécurité et la confidentialité des données sont primordiales pour nous. AIQIDO est conforme au RGPD et utilise un cryptage de bout en bout pour toutes les interactions. Nos serveurs sont hébergés dans des centres de données sécurisés, et nous effectuons des audits de sécurité réguliers pour garantir la protection de vos données.',
  },
  {
    question: "Puis-je personnaliser la voix et le ton d'AIQIDO pour qu'ils correspondent à ma marque ?",
    answer:
      "Oui, la personnalisation est l'un des points forts d'AIQIDO. Notre interface de configuration vous permet d'ajuster facilement la voix, le ton, et même le vocabulaire utilisé par l'IA pour qu'ils s'alignent parfaitement avec l'identité de votre marque. Vous pouvez effectuer ces ajustements en temps réel, sans aucune connaissance technique requise.",
  },
];

const FAQItem = ({ question, answer }: { question: string; answer: string }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-5">
      <button
        className="flex items-center justify-between w-full px-4 py-4 text-lg text-left text-gray-800 rounded-lg bg-gray-50 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-indigo-500 focus-visible:ring-opacity-75 dark:bg-gray-800 dark:text-gray-200"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{question}</span>
        <ChevronDown className={`w-5 h-5 text-primary ${isOpen ? 'transform rotate-180' : ''}`} />
      </button>
      {isOpen && <div className="px-4 pt-4 pb-2 text-gray-600 dark:text-gray-400">{answer}</div>}
    </div>
  );
};

export const FAQSection = () => {
  return (
    <section
      id="faq"
      className="py-20 bg-white dark:bg-gray-900"
    >
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center">Foire Aux Questions</h2>
        <div className="max-w-3xl mx-auto">
          {faqList.map((item, index) => (
            <FAQItem
              key={index}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
