'use client';

import { Target, Zap, Award, Link as LinkIcon } from 'lucide-react';

const benefits = [
  {
    icon: Target,
    title: 'Identification précise des leads',
    description: 'Analyse comportementale pour cibler les visiteurs à fort potentiel',
  },
  {
    icon: Zap,
    title: 'Engagement immédiat',
    description: 'Interactions vocales en temps réel pour convertir rapidement',
  },
  {
    icon: Award,
    title: 'Qualification optimisée',
    description: 'Moins de leads non qualifiés, plus de conversions',
  },
  {
    icon: LinkIcon,
    title: 'Intégration CRM fluide',
    description: 'Synchronisation automatique des leads avec vos systèmes',
  },
];

export const BenefitsSection = () => {
  return (
    <section
      id="benefits"
      className="py-20 bg-gray-100 dark:bg-gray-800"
    >
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-8 text-center">Maximisez vos opportunités de vente</h2>
        <p className="text-xl text-center max-w-3xl mx-auto mb-12">
          Concentrez vos efforts sur les prospects les plus qualifiés et convertissez-les grâce à une interaction
          directe et personnalisée.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-700 rounded-lg p-6 shadow-lg"
            >
              <benefit.icon className="w-12 h-12 text-primary mb-4" />
              <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
              <p className="text-gray-600 dark:text-gray-300">{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
