'use client';

export const ProblemSection = () => {
  return (
    <section className="py-20 bg-gray-100 dark:bg-gray-800">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-8 text-center">
          Manquez-vous des opportunités de leads avec intention d'achat ?
        </h2>
        <p className="text-xl text-center max-w-3xl mx-auto">
          De nombreux visiteurs à forte intention d'achat quittent votre site sans être identifiés ni engagés. Notre
          solution vous aide à capter ces prospects avant qu'ils ne partent.
        </p>
      </div>
    </section>
  );
};
