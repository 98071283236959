'use client';

import { Brain, MessageSquare, UserCheck, Database } from 'lucide-react';

const features = [
  {
    icon: Brain,
    title: 'Analyse comportementale en temps réel',
    description: "Identifie les visiteurs avec intention d'achat grâce à l'IA",
  },
  {
    icon: MessageSquare,
    title: 'Conversations dynamiques',
    description: 'Engage des échanges vocaux adaptés aux besoins des prospects',
  },
  {
    icon: UserCheck,
    title: 'Qualification des leads',
    description: 'Évalue la qualité des leads basée sur leur comportement de navigation',
  },
  {
    icon: Database,
    title: 'Intégration CRM',
    description: 'Synchronise automatiquement les leads intentionnistes avec votre CRM',
  },
];

export const SolutionSection = () => {
  return (
    <section
      id="solution"
      className="py-20 bg-white dark:bg-gray-900"
    >
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-8 text-center">
          Analysez et engagez automatiquement les visiteurs intentionnistes en temps réel
        </h2>
        <p className="text-xl text-center max-w-3xl mx-auto mb-12">
          Notre IA proactive détecte les visiteurs les plus intentionnistes sur votre site web en analysant leur
          comportement. Elle engage automatiquement des conversations dynamiques adaptées à leurs besoins, pour lever
          les objections, répondre à leurs questions et transformer leurs intentions d'achat en conversions.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-gray-100 dark:bg-gray-800 rounded-lg p-6 flex items-start"
            >
              <feature.icon className="w-12 h-12 text-primary mr-4 flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600 dark:text-gray-300">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
